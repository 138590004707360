import PreQualificationRestApi from 'api/PreQualificationApi';
import ArgyleRestApi from 'api/ArgyleApi';
import LoanOfferRestApi from 'api/LoanOfferApi';
import PreQualificationDeclineRestApi from 'api/PreQualificationDeclineApi';
import DocuSignEnvelopeRestApi from 'api/DocuSignEnvelopeApi';
import ApplicationDataRestApi from 'api/ApplicationDataApi';
import ApplicationDocumentsRestApi from 'api/DocumentsApi';
import EmployersApi from 'api/EmployersApi';
import PinwheelApiRest from 'api/PinwheelApi';
import AtomicApiRest from 'api/AtomicApi';
import UserDataApiRest from 'api/UserDataApi';
import PlaidRestApi from 'api/PlaidApi';
import EmailRestApi from 'api/EmailApi';
import PaymentAccountRestApi from 'api/PaymentAccountApi';
import MethodRestApi from 'api/MethodApi';
import CardRestApi from 'api/CardApi';
import ACHPaymentApi from 'api/PaymentApi';

import { getPlanneryApiUrl } from 'utils/getApiUrls';

import { createGetPreQualificationDataThunk } from './createPreQualificationThunk';
import { createGetPayrollUserDataThunk } from './createGetPayrollUserDataThunk';
import { createLoanOfferThunk } from './createLoanOfferThunk';
import { createLoanOfferXSellThunk } from './createLoanOfferXSellThunk';
import { createPreQualificationDeclineThunk } from './createPreQualificationDeclineThunk';
import { createDocuSignEnvelopeThunk } from './createDocuSignEnvelopeThunk';
import { createGetApplicationDataThunk } from './createGetApplicationDataThunk';
import { createBankAccountThunk } from './createBankAccountThunk';
import { createUploadDocumentsThunk } from './createUploadDocumentsThunk';
import { createApplicationStatusThunk } from './createApplicationStatusThunk';
import { createPayDistributionConfigsEncryptThunk } from './createPayDistributionConfigsEncryptThunk';
import { createGetLoanAmountDataThunk } from './createGetLoanAmountDataThunk';
import { createGetCreditScoreRangeDataThunk } from './createGetCreditScoreRangeDataThunk';
import { createUserLocationThunk } from './createUserLocationThunk';
import createSearchEmployersThunk from './createSearchEmployersThunk';
import createCreatePinwheelLinkTokenThunk from './createCreatePinwheelLinkTokenThunk';
import createCreateAtomicAccessTokenThunk from './createCreateAtomicAccessTokenThunk';
import { createLinkTokenThunk } from './createLinkTokenThunk';
import { createSetBankAccountsThunk } from './createSetBankAccountsThunk';
import { createCreateApplicationThunk } from './createCreateApplicationThunk';
import { createGetCheckingAccounts } from './createGetCheckingAccountsThunk';
import { createUpdateApplicationCheckingAccountThunk } from './createUpdateApplicationCheckingAccountThunk';
import { createSendOfferComparisonEmailThunk } from './createSendOfferComparisonEmail';
import { createFeedbackThunk } from './createFeedbackThunk';
import { createPaymentAccountCreationThunk } from './createPaymentAccountCreationThunk';
import { createGetPaymentAccountThunk } from './createGetPaymentAccountThunk';
import { createCitizenshipThunk } from './createCitizenshipThunk';
import { createUpdateEmploymentDataThunk } from './createUpdateEmploymentDataThunk';
import { createGetEmployerData } from './createGetEmployerDataThunk';
import { createSendEnrollmentFormEmail } from './createSendEnrollmentFormEmailThunk';
import { createUpdateSelectedTradelinesThunk } from './createUpdateSelectedTradelinesThunk';
import { createElementTokenThunk } from './createElementTokenThunk';
import { createLoanManagementThunk } from './createLoanManagementThunk';
import { createVerifyApplicationThunk } from './createVerifyApplicationThunk';
import { createCardApplyThunk, createCardApplyWithLoanOfferThunk } from './createCardApplyThunk';
import { createACHPaymentThunk } from './createACHPaymentThunk';
import { createGetApplicationAprThunk } from './createGetApplicationAprThunk';

export interface ErrorResponse {
  response: {
    data: unknown;
  };
}

const apiUrl = getPlanneryApiUrl();

const preQualificationApi = new PreQualificationRestApi(apiUrl);
const argyleApi = new ArgyleRestApi(apiUrl);
const loanOfferApi = new LoanOfferRestApi(apiUrl);
const preQualificationDeclineApi = new PreQualificationDeclineRestApi(apiUrl);
const docuSignEnvelopeApi = new DocuSignEnvelopeRestApi(apiUrl);
const applicationApi = new ApplicationDataRestApi(apiUrl);
const documentsApi = new ApplicationDocumentsRestApi(apiUrl);
const employersApi = new EmployersApi(apiUrl);
const pinwheelApi = new PinwheelApiRest(apiUrl);
const atomicApi = new AtomicApiRest(apiUrl);
const userDataApi = new UserDataApiRest(apiUrl);
const plaidApi = new PlaidRestApi(apiUrl);
const emailApi = new EmailRestApi(apiUrl);
const paymentAccountApi = new PaymentAccountRestApi(apiUrl);
const methodApi = new MethodRestApi(apiUrl);
const cardApi = new CardRestApi(apiUrl);
const paymentApi = new ACHPaymentApi(apiUrl);

export const getUserLocation = createUserLocationThunk(preQualificationApi);
export const getPreQualificationData = createGetPreQualificationDataThunk(preQualificationApi);
export const getLoanAmountData = createGetLoanAmountDataThunk(preQualificationApi);
export const getCreditScoreRangeData = createGetCreditScoreRangeDataThunk(preQualificationApi);
export const getUserPayrollData = createGetPayrollUserDataThunk(userDataApi);
export const getLoanOffer = createLoanOfferThunk(loanOfferApi);
export const getLoanOfferXSell = createLoanOfferXSellThunk(loanOfferApi);
export const sendNotification = createPreQualificationDeclineThunk(preQualificationDeclineApi);
export const createDocuSignEnvelope = createDocuSignEnvelopeThunk(docuSignEnvelopeApi);
export const getApplicationData = createGetApplicationDataThunk(applicationApi);
export const getApplicationApr = createGetApplicationAprThunk(applicationApi);
export const createApplication = createCreateApplicationThunk(applicationApi);
export const updateApplicationBankAccountData = createBankAccountThunk(applicationApi);
export const uploadDocuments = createUploadDocumentsThunk(documentsApi);
export const updateApplicationStatus = createApplicationStatusThunk(applicationApi);
export const getPayDistributionConfigsEncrypt = createPayDistributionConfigsEncryptThunk(argyleApi);
export const searchEmployers = createSearchEmployersThunk(employersApi);
export const createPinwheelLinkToken = createCreatePinwheelLinkTokenThunk(pinwheelApi);
export const createAtomicAccessToken = createCreateAtomicAccessTokenThunk(atomicApi);
export const getLinkToken = createLinkTokenThunk(plaidApi);
export const setBankAccounts = createSetBankAccountsThunk(plaidApi);
export const getCheckingAccounts = createGetCheckingAccounts(plaidApi);
export const updateApplicationCheckingAccount = createUpdateApplicationCheckingAccountThunk(applicationApi);
export const sendOfferComparisonEmail = createSendOfferComparisonEmailThunk(emailApi);
export const updateApplicationFeedbackData = createFeedbackThunk(applicationApi);
export const updateApplicationLoanManagementData = createLoanManagementThunk(applicationApi);
export const createPaymentAccount = createPaymentAccountCreationThunk(paymentAccountApi);
export const getPaymentAccount = createGetPaymentAccountThunk(paymentAccountApi);
export const updateCitizenshipData = createCitizenshipThunk(applicationApi);
export const updateEmploymentData = createUpdateEmploymentDataThunk(applicationApi);
export const getEmployerData = createGetEmployerData(employersApi);
export const sendDirectDepositEnrollmentFormEmail = createSendEnrollmentFormEmail(emailApi);
export const updateSelectedTradelines = createUpdateSelectedTradelinesThunk(applicationApi);
export const elementTokenThunk = createElementTokenThunk(methodApi);
export const verifyApplication = createVerifyApplicationThunk(loanOfferApi);
export const applyForCard = createCardApplyThunk(cardApi);
export const applyForCardWithApplication = createCardApplyWithLoanOfferThunk(cardApi);
export const makeACHPayment = createACHPaymentThunk(paymentApi);
